

import authReducer, { updateUser, updateAuthStatus } from './authSlice'

export const AUTH_TYPES = {
    UPDATE_USER: 'UPDATE_USER',
    UPDATE_AUTH_STATUS: 'UPDATE_AUTH_STATUS'
}

export const AUTH_SLICE = {
    UPDATE_USER: updateUser,
    UPDATE_AUTH_STATUS: updateAuthStatus
}

export { authReducer }
export default authReducer