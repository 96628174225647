import { Box, Typography } from '@mui/material'
import { AddButton, SubtractButton, RemoveButton } from './../base'
import { useDispatcher, TYPES, useSelector } from './../../store/dispatcher'
import { API } from './../../api'


const Display = ({count}) => {
    return <Box sx={{p: 1, background: '#222', color: '#eee', borderRadius: 1, fontWeight: 'bold'}}>
        {count}
    </Box>
}

export const CartButtons = props => {
    const dispatcher = useDispatcher()
    const { product_pk, count, withRemove=false, alignItems="end" } = props
    const handler = (action) => {

        const f = async() => {
            const data = await API.cart.update_cart(product_pk, action)
            dispatcher(TYPES.STORE.UPDATE_CART, {cart: data.cart})
        }   
        f()
    }
    return <Box sx={{width: withRemove ? 150 : 100, display: 'flex', flexDirection: 'column', alignItems: alignItems}}>
        <Box sx={{
            display: 'flex', 
            alignItems: 'center'
        }}>
            { !withRemove && <>
                <Display count={count}></Display>
                <AddButton onClick={() => handler('add')} />
                <SubtractButton onClick={() => handler('subtract')} />
            </>}
            { withRemove && <>
                <Display count={count}></Display>
                <AddButton onClick={() => handler('add')} />
                <SubtractButton onClick={() => handler('subtract')} />
                <RemoveButton sx={{color: t => 'red'}} onClick={() => handler('remove')} />
            </>}
        </Box>

    </Box>
}
export const CartButtonsReversed = props => {

    const dispatcher = useDispatcher()
    const count = useSelector(state => state.store.cart.count)
    const { product_pk, withRemove=false, alignItems="end" } = props
    const handler = (action) => {

        const f = async() => {
            const data = await API.cart.update_cart(product_pk, action)

            dispatcher(TYPES.STORE.UPDATE_CART, {cart: data.cart})
        }   
        f()
    }
    return <Box sx={{width: withRemove ? 150 : 100, display: 'flex', flexDirection: 'column', alignItems: 'end'}}>
        <Box sx={{
            display: 'flex', 
            alignItems: 'center'
        }}>
            { !withRemove && <>
                <SubtractButton onClick={() => handler('subtract')} />
                <AddButton onClick={() => handler('add')} />
                </>}
            { withRemove && <>
                <RemoveButton sx={{color: t => 'red'}} onClick={() => handler('remove')} />
                <SubtractButton onClick={() => handler('subtract')} />
                <AddButton onClick={() => handler('add')} />
            </>}
        </Box>

    </Box>
}
export default CartButtons