import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { useSelector } from './../../store/dispatcher'

export const CategoryBar = props => {
    const { categories } = useSelector(state => state.store)

    const navigate = useNavigate()
    const onClick = (category) => {
        navigate(`/?category=${category}`)
    }
    return <Box sx={{
        display: 'flex',
        position: 'relative',

        width: '100%',
        justifyContent: 'start', 
        alignItems: 'center',
        cursor: 'pointer',
        fontSize: '1rem',
        flexWrap: 'wrap',
        pl: {
            xs: 0,
            sm: 1,
            md: 2,
            lg: 5
        },


        '& *': {fontFamily: t => t.typography.navbar.fontFamily}
    }}>
        {
            categories.map(ci => {
                return <Box 
                    className={ci.active ? 'active' : ''} 
                    key={ci.name}
                    onClick={evt => onClick(ci.name)} 
                    sx={{
                        fontSize: '.9rem',
                        p: 1,
                        '&.active': {
                            background: t => t.palette.secondary.dark,
                            // color: t => t.palette.primary.main
                        }       
                    }}
                >{ci.label}</Box>
            })
        }
    </Box>
}
