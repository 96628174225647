import { Box } from '@mui/material'
import { Thumbnails } from './Thumbnails'
import { useBreakpoint } from './../../components/base'
import { prepStaticFileUrl } from './../../tools'
const heights = {
    xs: 300,
    sm: 400,
    md: 400,
    lg: 400,
    xl: 400
}

const Image = ({src, alt, width}) => {
    let newSrc = ''
    if(src) {
        newSrc = prepStaticFileUrl(src)
    }

    return <Box sx={{width, height: '100%', objectFit: 'contain'}}>
        <img src={newSrc} alt={alt} style={{width: '100%', height: '100%', objectFit: 'contain'}} />
    </Box>

}

export const MainImage = props => {
    const {src, alt, height} = props
    const breakpoint = useBreakpoint()



    return <Box sx={{
        width: '100%', 
        height: height,
        outline: '1px solid rgba(0,0,0,.15)'

    }}>
        <Image src={src} alt={alt} />
    </Box>
}