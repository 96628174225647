export const email = (value) => {
    if (!value) {
      return 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(value)) {
      return 'Email address is invalid';
    }
    return '';
};
export const required = value => !value ? 'This field is required' : ''

export const Validators = {
    email,
    required
}