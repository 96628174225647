import { useEffect, useState } from 'react'
import CancelIcon from '@mui/icons-material/Cancel';
import {
    Box,
    Button,
    Typography,
    Modal
} from '@mui/material'
import API from './../../api/'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 300,
    sm: 400
  },
  bgcolor: t => t.palette.secondary.light,
  boxShadow: 24,
  p: 4,
};



const QuantityErrors = ({messages}) => {

    return <Box sx={{width: '100%', pt: 2, fontSize: '.9rem'}}>
        { messages.map(mi => {
                return <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'start', gap: 1, mt: 1}}>
                    <CancelIcon size="small" color="error" variant="filled" />
                    <Typography>{mi}</Typography>
                </Box>
          })
        }
        { messages.length > 0 && <Typography sx={{fontSize: '.9rem', fontStyle: 'italic', mt: 2}}>This can happen when an item is purchased after you've added to your cart but before you processed your payment. Please update your cart and try again.</Typography>

        }
    </Box>

}
export function ConfirmationModal({open, setOpen, onYes, cart, state}) {

    const [finalPrice, setFinalPrice] = useState(false)
    const [quantityErrorMessages, setQuantityErrorMessage] = useState([])
    const [error, setError] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const onClick = async (evt) => {
        await onYes(evt)
        handleClose()
    }
    useEffect(() => {
        const f = async () => {
            try {
                const data = await API.cart.compute_total({cart, state})
                if(data.quantities_pass) {
                    setFinalPrice(data['total'])
                } else {
                    setError(true)
                    setQuantityErrorMessage(data['messages'])
                }

            } catch {
                // display error message
                setError(true)
            }
            
        }
        if(open) {
            f()
        }
    }, [open])

    return (
        <Box>
        { (!finalPrice && !error) ? <Box></Box> : <>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                { error ? <>
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                There was an issue processing your request. Please try again. 
                            </Typography>
                            <QuantityErrors messages={quantityErrorMessages}/>
                            <Box sx={{p: 0, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Button sx={{p: 0, mt: 2, justifyContent: 'start'}} onClick={handleClose} variant="text">Close</Button>
                            </Box>
                        </Box>
                    </> : <>
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Confirm you would like to charge ${finalPrice} to your card. 
                            </Typography>
                            <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Button onClick={handleClose} variant="text">No</Button>
                                <Button variant="text" onClick={onClick}>Yes</Button>
                            </Box>
                        </Box>
                </>
            }
            </Modal>
        </>
        }
        </Box>
    );
}