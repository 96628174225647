const BASE_URL = process.env.REACT_APP_BASE_URL

const accessTokenOrNone = async () => {
    try {
      // Get the tokens from localStorage or cookies
      const refreshToken = localStorage.getItem('refresh_token');
      const accessToken = localStorage.getItem('access_token');
  
      // Initialize the authentication status
      let isAuthenticated = false;
  
      // If there's no refresh token, the user is not authenticated
      if (!refreshToken) {
        return isAuthenticated;
      }
  
      // Check if the access token is expired
      if (isTokenExpired(accessToken)) {
        // If the access token is expired, try to refresh it
        const newTokens = await refreshTokenIfNeeded(refreshToken);
  
        if (newTokens) {
          // If refreshing was successful, update tokens in localStorage
          localStorage.setItem('access_token', newTokens.access);
          localStorage.setItem('refresh_token', newTokens.refresh);
          isAuthenticated = true;
        }
      } else {
        // If access token is valid, user is authenticated
        isAuthenticated = true;
      }
      
      return isAuthenticated ? localStorage.getItem('access_token') : null

    } catch (error) {
      console.error('Error checking authentication status:', error);
      return false;
    }
  };
  
  // Function to check if a token is expired
  const isTokenExpired = (token) => {
    if (!token) return true;
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expiry = payload.exp * 1000; // JWT expiry is in seconds
    return Date.now() > expiry;
  };
  
  // Function to refresh the access token using the refresh token
  const refreshTokenIfNeeded = async (refreshToken) => {
    
    try {
      const response = await fetch(BASE_URL + '/api/auth/token/refresh/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ refresh: refreshToken }),
      });
  
      if (response.ok) {
        const data = await response.json();
        return {
          access_token: data.access,
          refresh_token: data.refresh,
        };
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      return null;
    }
  };
  



export const fetcher = async (url, params) => {
    const access_token = await accessTokenOrNone()
    const cartnumber = localStorage.getItem('cartnumber')


    let headers = params.headers ? params.header : {}
    headers['Content-Type'] = 'Content-Type' in headers ? headers['Content-Type'] : 'application/json'
    // if(true) {
    //     headers['Authorization'] = 'Bearer ' + access_token
    // }
    let body = 'body' in params ? params.body : {}
    body = Object.assign({}, body, {cartnumber})

    const u = BASE_URL + url 

    return fetch(u, {
        method: 'POST', 
        headers,
        body: JSON.stringify(body)
    })
}
export default fetcher
export {accessTokenOrNone}