import { Box, IconButton } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add';
import SubtractIcon from '@mui/icons-material/Remove';
import RemoveIcon from '@mui/icons-material/Cancel';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const AddButton = ({onClick}) => {
    return <IconButton size="small" aria-label="add-single-item" onClick={onClick}>
        <AddIcon />
    </IconButton>
}

export const SubtractButton = ({onClick}) => {
    return <IconButton size="small" aria-label="remove-single-item" onClick={onClick}>
        <SubtractIcon />
    </IconButton>
}

export const RemoveButton = ({onClick}) => {
    return <IconButton size="small" aria-label="remove-item" onClick={onClick} sx={{color: t => 'red'}}>
        <RemoveIcon />
    </IconButton>
}


export const BackToProductListButton = () => {
    const navigate = useNavigate()
    return <Box sx={{width: '100%'}}>
        <IconButton onClick={() => navigate('/')}><ArrowBackIcon size="small" /></IconButton>
    </Box>
}