import { Box, Typography, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CartButtons } from './../../components/session'
import { alpha } from '@mui/material/styles'
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MuiDivider from '@mui/material/Divider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from './../../store/dispatcher'
import { Sold } from './../../components/products'
const Divider = () => {
    return <MuiDivider sx={{
        border: t => `1px solid ${alpha(t.palette.primary.main, .2)}`,
        width: '100%',
        mt: 1,
        mb: 1
    }}></MuiDivider>
}

const CartDivider = () => {
    return <Box sx={{width: '100%'}}>
        <MuiDivider textAlign="left" sx={{
            width: '100%'
        }}>Add To Cart</MuiDivider>
    </Box>
}
const ShippingIcon = () => {

    return <Box sx={{
        color: t => t.palette.primary.main,
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        gap: 1,
        mr: 2

    }}>
        <LocalShippingIcon color="primary" size="large"></LocalShippingIcon>
    </Box>
}

export const ProductInfo = props => {
    const { height, product } = props
    console.log(product)
    const navigate = useNavigate()
    const count = useSelector(state => {
        if(!state.store.cart.items) { return 0 }

        let item = state.store.cart.items.filter(pi => pi.product.pk === product.pk)
        item = item.length ? item[0] : null

        return item ? item.quantity : 0

    })

    return <Box sx={{
        width: '100%', 
        minHeight: height,
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'start', 
        justifyContent: 'center'
    }}>

        <Box sx={{maxWidth: 500, wrap: 'wrap', mt: 1}}><Typography  variant="productTitle">{product.name}</Typography></Box>
        <Box sx={{maxWidth: 500, wrap: 'wrap', mt: 1}}><Typography  variant="productDescription">{product.description}</Typography></Box>
        <Divider />
        <Box sx={{maxWidth: 500, wrap: 'wrap', mt: 1, fontWeight: 'bold'}}></Box>
        <Box sx={{mt: 2, width: '100%', display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
            <Typography sx={{fontWeight: 'bold', mr: 2}}>${product.price}.00</Typography>
            <Typography sx={{ml: 'auto', mr: 1}}> + $0.00  </Typography>
            <ShippingIcon /> <Typography > Free Shipping </Typography>
        </Box>

        <Box sx={{mt: 2}}></Box>
        <CartDivider />
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
            {product.quantity > 0 ? <CartButtons product_pk={product.pk} count={count}/> : <Sold />}
            
            <Box>
                <Button sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1}} onClick={() => navigate(-1)}>
                    <ArrowBackIcon />
                    Back to shopping
                </Button>
            </Box>
        </Box>
    </Box>
}