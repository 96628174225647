import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export const TruncatedText = ({ text, nlines, handler, variant }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = handler ? handler : () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box sx={{m: 0, p: 0}}>
      <Typography
        variant={variant ? variant : 'body1'}
        style={{
          display: '-webkit-box',
          WebkitLineClamp: isExpanded ? 'none' : nlines,
          WebkitBoxOrient: 'vertical',
          overflow: isExpanded ? 'visible' : 'hidden',
          textOverflow: isExpanded ? 'initial' : 'ellipsis',
        }}
      >
        {text}
      </Typography>
      <Typography variant="seemore" sx={{color: t => t.palette.primary.dark}} onClick={handleToggle}>
        {isExpanded ? 'See Less' : '(See More)'}
      </Typography>
    </Box>
  );
};

export default TruncatedText;