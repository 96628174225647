
import { useEffect, useState } from 'react'
import Grid from '@mui/material/Grid2'

import {
    Box,
    Typography
} from '@mui/material'
import { API } from './../../api'
import { LazyImage } from './../../components/base'

const Name = props => {
    return <Typography sx={{
        fontWeight: 'bold', 
        fontSize: '1.2rem',
    }}>
        {props.children}
    </Typography>
}
const Description = props => {
    return <Typography sx={{
        opacity: .8
    }}>
        {props.children}
    </Typography>
}
const Image = ({src, alt}) => {
    return <Box sx={{width: 100, height: 100, position: 'relative'}}>
        <LazyImage src={src} alt={alt} />
    </Box>
}
const Price = (props) => {
    return <Typography sx={{fontWeight: 'bold'}}>
        ${props.children}
    </Typography>
}

const Link = ({url}) => {
    const onClick = evt => {
        window.open(url, '_blank')
    }
    return <Box onClick={onClick} sx={{
        cursor: 'pointer', 
        '& *:hover': {
            color: t => t.palette.primary.light
        }
    }}>
        <Typography sx={{
            color: t => t.palette.primary.main

        }}>
            See Product Link
        </Typography>
    </Box>
}


const Card = ({product}) => {
    const pi = product

    return <Grid item size={{xs: 12}} key={pi.product_id} sx={{mt: 3}}>
        <Box sx={{display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'start'}}>
            <Image src={pi.image} alt={pi.name} />
            <Box sx={{}}>
                <Name>{pi.name}</Name>
                <Description >{pi.description}</Description>
                <Price>{pi.store_price}</Price>
                <Link url={pi.product_link}>See Product Link</Link>
            </Box>

        </Box>

    </Grid>
}

export const PotentialProductPage = () => {

    const [products, setProducts] = useState(null)
    const keyword = useState('wire')
    useEffect(() => {
        const f = async () => {
            const response = await API.pages.get_potential_products({keyword})
            if(response.ok) {
                const data = await response.json()
                setProducts(data.products)
            }
        }
        f()
    }, [])

    if(!products) return <></>

    return <>
        <Grid container spacing={2} sx={{width: '100%', display: 'flex'}}>
            {
                products.map(pi => <Card product={pi} />)
            }
        </Grid>
    
    </>

}