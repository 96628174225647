
import { 
    BasePage,
    HomePage,
    ProductDetailPage,
    CheckoutPage,
    ContactPage,
    PotentialProductPage
} from './../pages'

export const routes = [
    {
        path: '/',
        exact: true,
        element: <BasePage key="hi"/>,
        children: [
            {
                path: '/', 
                exact: true,
                element: <HomePage />
            },
            {
                path: 'product-detail/:id/',
                exact: true, 
                element: <ProductDetailPage key="product-detail"/>
            },
            {
                path: 'checkout/',
                element: <CheckoutPage />
            },
            {
                path: 'contact/',
                element: <ContactPage />
            },
            {
                path: 'wires/',
                element: <PotentialProductPage />
            }
        ]
    }

]


export default routes;