
import {Helmet} from 'react-helmet'
import { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import {Box} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline';
import {loadStripe} from "@stripe/stripe-js/pure";
import {Elements} from '@stripe/react-stripe-js';
import { useRoutes } from 'react-router-dom';

import routes from './routes';
import useTheme from './theme'
import { getStripe } from './tools/'
import './App.css'
const stripePromise = getStripe()
export const App = () => {
  const [mode, setMode] = useState('light') // for darktheme - if there was one
  const content = useRoutes(routes)
  const theme = useTheme('light')

  return <>
    <Helmet>
        <title>OverstockGR</title>
    </Helmet>
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Elements stripe={stripePromise}>
            <Box className="main-wrapper" sx={{
              width: '100%', 
              height: '100%', 
              position: 'relative',
              padding: 0,
              margin: 0, 
              background: t => t.palette.primary.background, 
              overflowX: 'hidden'
            }}>
              {content}
            </Box>

        </Elements>
    </ThemeProvider>


  </>
}

export default App