
import { Box } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import TitleSmall from './../../assets/title/title_small.png'
import TitleMedium from './../../assets/title/title_medium.png'
import TitleLarge from './../../assets/title/title_large.png'
import { CategoryBar } from './CategoryBar'
import { useSelector } from './../../store/dispatcher'
const Title = () => {
    const theme = useTheme();

    // Define breakpoints
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMedium = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isLarge = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isXLarge = useMediaQuery(theme.breakpoints.up('lg'))
  
    // Determine the image to show based on screen size
    let imageUrl = TitleSmall
  
    if (isMedium) {
      imageUrl = TitleSmall// Example for medium screens
    } else if (isLarge) {
      imageUrl = TitleMedium; // Example for large screens
    } else if (isXLarge)
        imageUrl = TitleLarge
    return (
      <div>
        <img src={imageUrl} alt="OverstockGR" style={{ width: '100%', height: 'auto' }} />
      </div>
    );
}


const NavbarLink = props => {
    return <Link 
        style={{
            textDecoration: 'none', 
            color: 'inherit',
        }}
        {...props}
    >
        { props.children }
    </Link>
}


const NavbarItem = props => {
    const {name, icon, to } = props
    return <NavbarLink to={to}>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column', 
            alignItems: 'center',
            fontSize: {
                xs: '.7rem',
                md: '.9rem',
                lg: '1rem'
            },
            p: {
                xs: 0, 
                sm: 0,
                md: 0,
                lg: 1
            },
            position: 'relative',
            borderRadius: 4,
            '&:hover': {
                background: 'rgba(0,0,0,.1)',
                borderRadius: 0
            }
        }}>
            { props.children }
        </Box>
    </NavbarLink> 
    
}

const CartOrnament = () => {
    const count = useSelector(state => state.store.cart.count ? state.store.cart.count : 0)
    return <Box sx={{
        background: 'red', 
        borderRadius: '50%', 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        position: 'absolute',
        color: 'white',
        top: 0, 
        width: {
            xs: 15,
            sm: 20,
            md: 20,
            lg: 25
        },
        height: {
            xs: 15,
            sm: 20,
            md: 20,
            lg: 25
        },
        top: {
            xs: -3,
            sm: -5,
            md: -8,
            lg: -10
        },
        right: {
            xs: -3,
            sm: -5,
            md: -8,
            lg: -1
        },
        fontWeight: 'bold'

    }}>
        { count }
    </Box>
}
export const Navbar = props => {

    return <Box sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: t => t.palette.background.navbar,
        pl: {
            xs: 1,
            sm: 2,
            md: 3,
            lg: 5
        },
        pr: {
            xs: 1,
            sm: 2,
            md: 3,
            lg: 5
        },
        pt: {
            xs: 1,
            sm: 2,
            md: 3,
            lg: 5
        },
        position: 'relative',
        '&::before': {
            content: "''",
            position: 'absolute',
            width: '100%', 
            height: 2, 
            background: t => t.palette.background.navbar,
            bottom: 0,
            left: 0
        }
    }}>
        <Box className="left-side" sx={{display: 'flex'}}>
            <NavbarItem to="/"><Title /></NavbarItem>
        </Box>
        <Box className="middle" sx={{display: 'flex'}}></Box>
        <Box className="right-side" sx={{display: 'flex', gap: 1, '&, & *': {color: t => t.palette.primary.main}}}>
            <NavbarItem to="/contact/"> <ContactMailIcon /> Contact </NavbarItem>
            <NavbarItem to="/checkout/" >                    
                <ShoppingCartIcon sx={{color: t => t.palette.primary.main}}/>
                <CartOrnament />
                Checkout
            </NavbarItem>
        </Box>
    </Box>
}

export default Navbar