import { useEffect, useState } from 'react'

import { Box } from '@mui/material'
import  Grid  from '@mui/material/Grid2'
import { Link, useLocation } from 'react-router-dom'
import { API } from './../../api'
import { prepStaticFileURL } from './../../tools'
import { useSelector, useDispatcher, TYPES } from './../../store/dispatcher'
import { Spinners } from './../../components/base'
import {ProductCard} from './../../components/products/'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export const HomePage = props => {
    const dispatcher = useDispatcher()

    const cart = useSelector(state => state.store.cart)
    const products = useSelector(state => state.store.products)
    const _products = null
    const query = useQuery()
    const category = query.get('category');
    
    const [categories, setCategories] = useState([])
    const [trigger, setTrigger] = useState(true)

    const updateTrigger = () => {
        setTrigger(!trigger)
    }

    useEffect(() => {
        dispatcher(TYPES.STORE.UPDATE_ACTIVE_CATEGORY, {category})
    }, [category])

    return <>
        { !products &&
            <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <Box>Grabbing inventory....</Box>
                <Box sx={{pt: 2}}><Spinners /></Box>
            </Box>
        }
        {
            products && 
            <Grid container spacing={3} fullWidth>
                {
                    products.filter(pi => pi.category == category || !category || category === 'all').map((product, i) => {
                        return <Grid key={product.pk} size={{xs: 12, sm: 6, md: 4, lg: 3, xl: 3}}>
                            <Link to={`product-detail/${product.pk}/`}>
                                <ProductCard product={product} />
                            </Link>
                        </Grid>
                    })
                }
            </Grid>

            
        }
    </>
}

/*
import { useEffect, useState } from 'react'

import { Link, useLocation } from 'react-router-dom'
import { API, prepStaticFileUrl } from './../../tools'
import {ProductCard} from './../../components/products/'
import { useDispatcher, TYPES } from './../../store/dispatcher';
import { useSelector } from 'react-redux'
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export const HomePage = props => {
    const dispatcher = useDispatcher()
    const cart = useSelector(state => state.store.cart)
    const products = useSelector(state => state.store.products)
    const query = useQuery()
    const category = query.get('category');


    // const [cart, setCart] = useState({})
    const [categories, setCategories] = useState([])
    
    const [trigger, setTrigger] = useState(true)

    const updateTrigger = () => {
        setTrigger(!trigger)
    }
    // useEffect(() => {
    //     const anon = async () => {
    //         const data = await API.pages.homepage()
    //         // setProducts(data.products)
    //         setCategories(data.categories)
    //         // dispatcher(TYPES.STORE.UPDATE_CART, {cart: data.cart})
    //     }
    //     if(trigger) {
    //         anon()
    //         updateTrigger()
    //     }
    // }, [trigger])

    useEffect(() => {
        dispatcher(TYPES.STORE.UPDATE_ACTIVE_CATEGORY, {category})
    }, [category])

    return <Box sx={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
    }}>
        <Box sx={{
            maxWidth: 1200,
            p: 3,
            background: t => t.palette.background.paper
        }}>
            { products.length &&
                <Grid container spacing={4}>
                {products
                    .filter(pi => category ? pi.category === category : true)
                    .map((product, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                    <Link to={`product-detail/${product.pk}/`}>
                        <ProductCard product={product} />
                    </Link>
                </Grid>
                ))}
                </Grid>
            }
            { products.length === 0 && 
                <div>No Data</div>
            }

        </Box>

    </Box>

}

export default HomePage
*/