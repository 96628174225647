import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

export const useProductID = () => {
    const location = useLocation()
    
    const [productID, setProductID] = useState(null)
    useEffect(() => {
        let l = location.pathname
        l = l.replaceAll('/', ' ').trim().split(' ')
        l = l[l.length-1]
        
        setProductID(l)
    }, [])

    return productID
}

export default useProductID