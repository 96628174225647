import { fetcher, accessTokenOrNone } from './fetcher'







function signup(values) {
    return fetcher('api/auth/registration/', {body: values})
        .then(data => {console.log(data); return data.status})
}

function login(values) {
    return fetcher('api/auth/login/', {body: values})
        .then(data => data.json())
        .then(data => {
            // store the refresh and access token to local stora
            const access = data.access ? data.access : null
            const refresh = data.refresh ? data.refresh : null 
            if(access && refresh) {
                localStorage.setItem('refresh_token', refresh)
                localStorage.setItem('access_token', access)
            }
            return data
        })
}

function logout(values) {
    return fetcher('api/auth/logout/', {})
        .then(data => {
            localStorage.removeItem('refresh_token')
            localStorage.removeItem('access_token')
        })
}

export const auth = {
    signup,
    login,
    logout,
    accessTokenOrNone
}