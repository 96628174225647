import { Box } from '@mui/material'
import { prepStaticFileUrl } from './../../tools'
import { LazyImage } from './../../components/base'
const Thumbnail = props => {
    const { size, slide, onClick } = props
    return <Box sx={{
        width: size, 
        height: size, 
        position: 'relative',
        '&::before': {
            content: '""',
            height: '100%', 
            width: slide.active ? 3 : 0,
            background: t => t.palette.primary.dark,
            position: 'absolute',
            top: 0,
            left: 0
        }
    }} onClick={evt => onClick(evt, slide)}>
        <LazyImage src={prepStaticFileUrl(slide.url)} alt={slide.name} />
    </Box>
}

export const Thumbnails = props => {
    const { slides, length, onClick, direction="vertical", img_size=50 } = props

    const height = length
    const width = img_size + 30
    const overflowX = direction === 'vertical' ? 'hidden' : 'scroll'
    const overflowY = direction === 'vertical' ? 'scroll' : 'hidden'
    return <Box sx={{
        width: width, 
        height: height, 
        position: 'relative',
        overflowY: 'hidden',
        p: 0,
        m: 0,
        cursor: 'pointer',
        outline: '1px solid rgba(0,0,0,.15)'
    }}>
        <Box sx={{
            overflowY: 'scroll', 
            overflowX: 'hidden',
            width: '100%', 
            height: '100%',
            background: t => t.palette.secondary.light,
            position: 'relative'
            
        }}
        >
            {slides && slides.map(slide => <Thumbnail key={slide.name} slide={slide} size={img_size} onClick={onClick}></Thumbnail>)}
        </Box>
        </Box>
}

