import Grid from '@mui/material/Grid2'
import { Box } from '@mui/material'
import { CartList } from './CartList'
import { PaymentForm } from './PaymentForm'
import { BackToProductListButton } from './../../components/base'
export const CheckoutPage = props => {
    return <>
        <Box sx={{width: '100%'}}><BackToProductListButton /></Box>
        <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} md={6} lg={4} sx={{maxWidth: 800}} >
                <CartList />
                <PaymentForm />
            </Grid>
        </Grid>
    </>
}
export default CheckoutPage