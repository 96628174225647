
import { loadStripe, Stripe } from '@stripe/stripe-js';
export { API } from './../api'

export function prepStaticFileUrl(url) {

    if(!url) { return null; }
    const BASE_URL = process.env.REACT_APP_BASE_URL

    if(url.startsWith('http')) {
        return url
    }
    if(url.startsWith('/static')) {
        return BASE_URL + url.slice(1)
    }
    if(url.startsWith('static')) {
        return BASE_URL + url
    }
    if(url.startsWith('/')) {
        return BASE_URL + 'static' + url 
    }
    return BASE_URL + 'static/' + url
}




let stripePromise = null
export const getStripe = () => {

  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');
  }
  return stripePromise;
};

