
import { 
    Box,
    Typography
} from '@mui/material'
import { prepStaticFileUrl } from '../../tools';
import { TruncatedText } from './../base'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Sold } from './Sold'
import { LazyImage } from './../../components/base'

const Card = props => {
    return <Box sx={{
        // p: {
        //     xs: 1,
        //     sm: 2, 
        //     md: 3
        // },
        borderRadius: '2px 2px 0px 0px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%', 
        position: 'relative',
        height: '100%',
        background: t => t.palette.background.card,
        overflow: 'hidden',
        borderBottom: t => `5px solid ${t.palette.secondary.main}`,
        '&:hover': {
            background: t => t.palette.secondary.light
        }   
    }}
    >{props.children}</Box>
}

const CardTools = props => {
    return <Box sx={{
        width: '100%', 
        position: 'relative', 
        height: 40
    }}>
        Card Tools
    </Box>
}

const CardImage = props => {
    const { alt, src } = props

    return <LazyImage src={src} alt={alt} />
}
export const ProductCard = props => {
    const { product } = props

    return <Card>
        <Box className="accent-stripe" sx={{borderRadius: '5px 5px 0px 0px', height: 3, pb: 2, width: '100%', top: 0, left: 0, 
            background: t=> t.palette.secondary.main}}></Box>
        <Box sx={{width: '100%', maxHeight: 400, position: 'relative'}}>
            <CardImage src={prepStaticFileUrl(product.base_image)} alt={product.name}/>
        </Box>
        <Typography gutterBottom variant="productTitle" component="div">
           {product.name}
        </Typography>

        <TruncatedText text={product.description} nlines={4} variant="productDescription"/>
        
        <Box sx={{width: '100%', mt: 'auto', alignSelf: 'end', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            ${product.price}.00

            { product.quantity > 0 ? <ShoppingCartIcon color="primary"/> : <Sold /> }
            
        </Box>

    </Card>
}

// import React from 'react';
// import Grid from '@mui/material/Grid';
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { Box } from '@mui/material'
// import { prepStaticFileUrl } from '../../tools';
// import { TruncatedText } from './../base'
// import { Assets } from './../../assets'

// const RatingStars = Assets.RatingStars





// export const ProductCard = ({ product }) => {

//   return (
//     <Card sx={{
//         display: 'flex',
//         flexDirection: 'column', 
//         height: '100%',

//     }}>
//       <Box sx={{width: 300}}></Box>
//       <CardMedia
//         component="img"
//         image={prepStaticFileUrl(product.base_image)}
//         alt={product.name}
//         sx={{

//             borderRadius: 2,

//         }}
//       />
//         <Box sx={{flex: 1}}></Box>
//       <CardContent>
//         <Typography gutterBottom variant="product-title" component="div">
//           {product.name} - {product.baseproduct_pk}
//         </Typography>
//         <Typography component="div" variant="body" color="text.secondary" sx={{mb: 2}}>
//             <TruncatedText text={product.description} nlines={4} />
//         </Typography>
//         {/* <RatingStars rating={product.rating} number_sold={product.number_sold}/> */}
//         <Box sx={{mt: 2, display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>
//             <Typography variant="h6" component="div" color="text.primary">
//                 ${product.price}
//             </Typography>
//             <Button size="small" color="primary">
//                 Add to Cart
//             </Button>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// }