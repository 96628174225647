import {
    Box,
    Typography
} from '@mui/material'
import { useSelector } from './../../store/dispatcher'


const MICHIGAN_SALES_TAX = .06


const ExtraRow = (props) => {

    return <Box sx={{borderTop: '1px solid rgba(0,0,0,.1)', display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
        {props.children}
    </Box>
}
export const PriceList = props => {
    const cart = useSelector(state => state.store.cart)
    const { state } = props
    
    if(!cart || !Object.keys(cart).length) {
        return <h1>no data</h1>
    }
    return <>
        <ExtraRow>
            <Typography>Promotions and Coupons</Typography>
            <Typography>$0.00</Typography>
        </ExtraRow>
        <ExtraRow>
            <Typography>Shipping and Handling</Typography>
            <Typography>$0.00</Typography>
        </ExtraRow>
        <ExtraRow>
            <Typography>Sub Total</Typography>
            <Typography>${cart.subtotal}</Typography>
        </ExtraRow>
        <ExtraRow>
            <Typography>Michigan Sales Tax</Typography>
            <Typography>${state === 'michigan' ? cart.michigan_tax : cart.tax}</Typography>
        </ExtraRow>
        <hr />
        <ExtraRow>
            <Typography>Total</Typography>
            <Typography>${state === 'michigan' ? cart.michigan_total : cart.total}</Typography>
        </ExtraRow>
    </>
}