import { Box } from '@mui/material'
import { CategoryBar } from './CategoryBar'
import { Navbar } from './Navbar'


export const Header = props => {
    const { height } = props
    return <Box>
        <Navbar />
        <CategoryBar />
    </Box>
}