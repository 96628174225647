import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import 'intersection-observer';  // Polyfill for older browsers

export const LazyImage = ({ src, alt, preload }) => {
  const [imageSrc, setImageSrc] = useState(preload ? src : '');  // State to manage the actual image source

  useEffect(() => {
    const imgElement = document.querySelector('img');  // Select the image element

    if (preload) {
      // Preload the image in the background
      const img = new Image();
      img.src = src;  // Preload the image by setting the source
      img.onload = () => setImageSrc(src);  // Set the loaded image once preloaded
    }

    if ('loading' in HTMLImageElement.prototype) {
      // Native lazy loading for modern browsers
      setImageSrc(src);
    } else if ('IntersectionObserver' in window) {
      // Fallback using IntersectionObserver for older browsers
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setImageSrc(src);  // Set the image source when it enters the viewport
            observer.unobserve(entry.target);  // Stop observing once image is loaded
          }
        });
      });

      observer.observe(imgElement);
    } else {
      // Fallback for very old browsers without IntersectionObserver
      setImageSrc(src);
    }
  }, [src, preload]);

  return (
    <Box
      component="img"
      src={imageSrc}  // Use React state to manage the image src
      alt={alt}
      loading="lazy"  // Native lazy loading for supported browsers
      sx={{
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
      }}
    />
  );
};
// import React, { useEffect } from 'react';
// import { Box } from '@mui/material';
// import 'intersection-observer';  

// export const LazyImage = ({ src, alt }) => {
//   useEffect(() => {
//     const lazyImages = document.querySelectorAll('img[data-src]');

//     if ('loading' in HTMLImageElement.prototype) {
//       // Native lazy loading for modern browsers
//       lazyImages.forEach(img => {
//         img.src = img.dataset.src;
//       });
//     } else if ('IntersectionObserver' in window) {
//       // Fallback using IntersectionObserver for older browsers
//       const observer = new IntersectionObserver((entries, observer) => {
//         entries.forEach(entry => {
//           if (entry.isIntersecting) {
//             const img = entry.target;
//             img.src = img.dataset.src;
//             observer.unobserve(img);
//           }
//         });
//       });

//       lazyImages.forEach(img => observer.observe(img));
//     } else {
//       // Fallback for very old browsers without IntersectionObserver
//       lazyImages.forEach(img => {
//         img.src = img.dataset.src;
//       });
//     }
//   }, []);

//   return (
//     <Box
//       component="img"
//       data-src={src}  // Use data-src for lazy loading
//       alt={alt}
//       loading="lazy"  // Native lazy loading
//       sx={{
//         width: '100%',
//         height: 'auto',
//         objectFit: 'contain',
//       }}
//     />
//   );
// };

