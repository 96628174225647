import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { 
    Box,
    Button,
    IconButton
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Grid from '@mui/material/Grid2'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { API } from './../../api'
import { prepStaticFileUrl } from './../../tools'
import { useProductID } from './useProductID'
import { useSelector, useDispatcher, TYPES } from './../../store/dispatcher'
import { Thumbnails } from './Thumbnails'
import { MainImage } from './MainImage'
import { ProductInfo } from './ProductInfo'
import { useBreakpoint } from './../../components/base'
import { Spinners } from './../../components/base'


const heights = {
    xs: 300,
    sm: 400,
    md: 400,
    lg: 400,
    xl: 400
}


const useMainImageUrl = () => {
    const url = useSelector(state => {
        const slide = state.store.thumbnails.slides.find(si => si.active)
        return slide ? slide.url : ''
    })
    return url.replace('.webp', '_full.webp')
}

export const ProductDetailPage = props => {
    const navigate = useNavigate()
    const dispatcher = useDispatcher()
    const productID = useProductID()
    const product = useSelector(state => state.store.product) 
    const slides = useSelector(state => state.store.thumbnails.slides)
    const vslides = useSelector(state => state.store.thumbnails.variant_slides)
    const bp = useBreakpoint()
    const thumblength = heights[bp] ? heights[bp] : 400
    const mainImageUrl = useMainImageUrl()


    const onThumbnailClick = async (evt, si) => {

        // 1 - if the left slides were clicked, we will update products if a product image was selectede
        //     otherwise if just update the active thumbnail
        // 2 - if a product image was selected, we also need to qauery the new product, then update both slide roles active
        const shouldChangeProduct = si.vid ? true : false
        if(shouldChangeProduct) {
            // query product 
            // const product = await API.cart.get_product({vid: si.vid})
            const updatedSlides = slides.map(ui => {
                const newUI = {...ui}
                newUI.active = ui.vid === si.vid
                return newUI
            })
            const updatedVSlides = vslides.map(vi => {
                const newUI = {...vi}
                newUI.active = vi.vid === si.vid 
                return newUI
            })
            dispatcher(TYPES.STORE.UPDATE_THUMBNAILS, {
                slides: updatedSlides, 
                variant_slides: updatedVSlides
            })

            const new_product = await API.cart.get_product({product_pk: si.product_pk, vid: si.vid})
            dispatcher(TYPES.STORE.UPDATE_PRODUCT, {...new_product})
            // update right slide role
        } else {
            // update left slide role
            const updated = slides.map(ui => {
                const newUI = {...ui}
                newUI.active = ui.url === si.url
                return newUI
            })
            dispatcher(TYPES.STORE.UPDATE_THUMBNAILS, {slides: updated})
        }
        // if(!si.vid) {
        //     const product = 
        //     // set the clicked product to active 
        //     dispatcher(TYPES.STORE.MARK_THUMBNAIL_AS_ACTIVE, {thumbnail: si, group: 'slides'})
        // }
    }

    useEffect(() => {
        if(!productID) { return }
        const anon = async () => {
            const data = await API.pages.product_detail_page(productID)
            // setMainImageUrl(data.product.base_image)
            dispatcher(TYPES.STORE.UPDATE_PRODUCT, {product: data.product})
            dispatcher(TYPES.STORE.UPDATE_THUMBNAILS, {
                variant_slides: data.variant_slides, 
                slides: data.slides
            })
        }
        anon()
        return () => {
            dispatcher(TYPES.STORE.UPDATE_PRODUCT, {product: null})
        }
        
    }, [productID])

    
    return <>
        { !product && 
            <Box sx={{width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', display: 'flex'}}>
                <Box>Grabbing inventory....</Box>
                <Box sx={{pt: 2}}><Spinners /></Box>
            </Box>
        }
        { product && 
            <Box sx={{width: '100%'}}>
                <Box sx={{width: '100%'}}>
                    <IconButton onClick={() => navigate(-1)}><ArrowBackIcon size="small" /></IconButton>
                </Box>
                <Grid container spacing={3}>
                    <Grid container size={{xs: 12, sm: 6}} sx={{display: 'flex', alignItems: 'start', flexWrap: 'nowrap', gap: 0}} spacing={0}>
                        <Thumbnails length={thumblength} slides={slides} direction="vertical" img_size={100} onClick={onThumbnailClick}></Thumbnails>
                        <MainImage src={mainImageUrl} alt={product.name} height={thumblength}></MainImage>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <ProductInfo product={product} height={thumblength}/>
                    </Grid>
                </Grid>
            </Box>
        }
    </>
}



// import { useEffect, useState } from 'react' 
// import { Box, Grid } from '@mui/material'
// import { API } from './../../tools'

// import { Thumbnails } from './../../components/base'
// import { prepStaticFileUrl } from './../../tools'
// import { useDispatcher, TYPES, useSelector } from './../../store/dispatcher'
// import { ProductInfo } from './ProductInfo'
// import { useProductID } from './useProductID'

// const useMainImageUrl = () => {
//     const url = useSelector(state => {
//         const slide = state.store.thumbnails.slides.find(si => si.active)
//         return slide ? slide.url : ''
//     })
//     return url.replace('.webp', '_full.webp')
// }

// const MainImage = ({src, alt, width}) => {
//     let newSrc = ''
//     if(src) {
//         newSrc = prepStaticFileUrl(src)
//     }

//     return <Box sx={{width, border: '1px solid blue'}}>
//         <img src={newSrc} alt={alt} style={{width: '100%', height: 'auto'}} />
//     </Box>

// }

// export const ProductDetailPage = () => {
//     const productID = useProductID()
//     const product = useSelector(state => state.store.product) 
//     const slides = useSelector(state => state.store.thumbnails.slides)
//     const vslides = useSelector(state => state.store.thumbnails.variant_slides)
//     const mainImageUrl = useMainImageUrl()

//     // const [mainImageUrl, setMainImageUrl] = useState()
//     const dispatcher = useDispatcher()
    

//     const onThumbnailClick = async (evt, si) => {

//         // 1 - if the left slides were clicked, we will update products if a product image was selectede
//         //     otherwise if just update the active thumbnail
//         // 2 - if a product image was selected, we also need to qauery the new product, then update both slide roles active
//         const shouldChangeProduct = si.vid ? true : false
//         if(shouldChangeProduct) {
//             // query product 
//             // const product = await API.cart.get_product({vid: si.vid})
//             const updatedSlides = slides.map(ui => {
//                 const newUI = {...ui}
//                 newUI.active = ui.vid === si.vid
//                 return newUI
//             })
//             const updatedVSlides = vslides.map(vi => {
//                 const newUI = {...vi}
//                 newUI.active = vi.vid === si.vid 
//                 return newUI
//             })
//             dispatcher(TYPES.STORE.UPDATE_THUMBNAILS, {
//                 slides: updatedSlides, 
//                 variant_slides: updatedVSlides
//             })

//             const new_product = await API.cart.get_product({product_pk: si.product_pk, vid: si.vid})
//             dispatcher(TYPES.STORE.UPDATE_PRODUCT, {...new_product})
//             // update right slide role
//         } else {
//             // update left slide role
//             const updated = slides.map(ui => {
//                 const newUI = {...ui}
//                 newUI.active = ui.url === si.url
//                 return newUI
//             })
//             dispatcher(TYPES.STORE.UPDATE_THUMBNAILS, {slides: updated})
//         }
//         // if(!si.vid) {
//         //     const product = 
//         //     // set the clicked product to active 
//         //     dispatcher(TYPES.STORE.MARK_THUMBNAIL_AS_ACTIVE, {thumbnail: si, group: 'slides'})
//         // }
//     }

//     useEffect(() => {
//         if(!productID) { return }
//         const anon = async () => {
//             const data = await API.pages.product_detail_page(productID)
//             // setMainImageUrl(data.product.base_image)
//             dispatcher(TYPES.STORE.UPDATE_PRODUCT, {product: data.product})
//             dispatcher(TYPES.STORE.UPDATE_THUMBNAILS, {
//                 variant_slides: data.variant_slides, 
//                 slides: data.slides
//             })
//         }
//         anon()
//     }, [productID])

//     if(!product || !Object.keys(product).length) {
//         return <>No Product ID</>
//     }

//     return <>
//         <Box sx={{width: '100%'}}>
//             <Grid container spacing={2} justifyContent="center">
//                 {/* First Column - Main Image and thumbnail */}
//                 <Grid item xs={12} md={12} lg={4}>
//                     <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
//                         <Box sx={{display: 'flex', alignItems:'start', textAlign: 'right'}}>
//                             <Box sx={{flex: {md: 0, lg: 1}}}></Box>
//                             <Thumbnails 
//                                 slides={slides} 
//                                 flexDirection="column" 
//                                 length={500}
//                                 onClick={onThumbnailClick}
//                             ></Thumbnails>
//                             <MainImage src={mainImageUrl} alt={product.name} width={400}></MainImage>
//                         </Box>
//                     </Box>
//                 </Grid>
//                 {/* Second Column */}
//                 <Grid item xs={12} md={12} lg={4} >
//                     <Box sx={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid green'}}>
//                         <ProductInfo onThumbnailClick={onThumbnailClick} vslides={vslides}/>
//                         <Box sx={{flex: {md: 0, lg: 1}}}></Box>
//                     </Box>
//                 </Grid>
//     </Grid>
        
//         </Box>
//     </>
// }

// export default ProductDetailPage
