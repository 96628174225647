import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    cart: {},
    products: [],
    categories: [],
    product: null,
    thumbnails: {
        variant_slides: [],
        slides: []
    }
}
const cartSlice = createSlice({
    name: 'store',
    initialState,
    reducers: {
        updateCart: (state, action) => {
            const { cart } = action.payload
            if(Object.keys(cart).length === 0) {
                return {
                    ...state,
                    cart: {}
                }
            }
            return {
                ...state,
                cart: {
                    ...state.cart,
                    ...cart
                }
            }
        },
        updateProducts: (state, action) => {
            const { products } = action.payload 
            const new_products = [...products]

            return {
                ...state, 
                products: new_products
            }
        },
        updateProduct: (state, action) => {
            const { product } = action.payload 
            if(!product) {
                return {
                    ...state, 
                    product: null,
                    thumbnails: {
                        variant_slides: [],
                        slides: []
                    }

                }
            }
            const updatedVSlides = state.thumbnails.variant_slides.map(vi => {
                const newUI = {...vi}
                newUI.active = vi.image_key === product.image_key
                return newUI
            })

            const activeSlide = state.thumbnails.slides.find(di => di.active)

            const updatedSlides = state.thumbnails.slides.map(ui => {
                const newUI = {...ui}

                newUI.active = ui.image_key === product.image_key
                return newUI
            })

            // ensure an active slide has been maintained, if not, set it to the original
            const hasActive = updatedSlides.filter(di => di.active)
            if(!hasActive.length) {

                updatedSlides.forEach(si => {
                    si.active = si.url === activeSlide.url
                })
            }

            return {
                ...state,
                thumbnails: {
                    ...state.thumbnails,
                    variant_slides: updatedVSlides,
                    slides: updatedSlides
                //     slides: updatedSlides, 
                //     variants: updatedVSlides
                },
                product: {
                    ...product
                }
            }
        },
        updateCategories: (state, action) => {
            const { categories } = action.payload 
            const c = categories.map(ci => {
                ci.active = ci.active ? ci.active : false

                return ci
            })
            return {
                ...state,
                categories: c
            }   
        },
        updateActiveCategory: (state, action) => {

            const { category } = action.payload
            const nextCats = state.categories.map(di => di)
            const categories = state.categories.map(ci => {
                return {
                    ...ci, 
                    active: ci.name === category
                }

            })
            return {
                ...state,
                categories
            }
        },
        updateThumbnails: (state, action) => {
            const { slides, variant_slides } = action.payload 
            const newSlides = slides ? slides : state.thumbnails.slides 
            const newVariants = variant_slides ? variant_slides : state.thumbnails.variant_slides
            return {
                ...state, 
                thumbnails: {
                    variant_slides: newVariants,
                    slides: newSlides

                }

            }
        },
        markThumbnailAsActive: (state, action) => {
            const { group, thumbnail } = action.payload
            let variant_slides = state.thumbnails.variant_slides.map(vi => {
                return {
                    ...vi, 
                    active: thumbnail.url === vi.url
                }
            })
            let slides = state.thumbnails.variant_slides.map(vi => {
                return {
                    ...vi, 
                    active: thumbnail.url === vi.url
                }
            })
            return {
                ...state,
                thumbnails: {
                    slides, 
                    variant_slides
                }
            }
        },

    },
    extraReducers: (builder) => {}

})



// Action creators are generated for each case reducer function
export const { 
    updateCart, 
    updateProducts,
    updateProduct,
    updateThumbnails,
    markThumbnailAsActive,
    updateCategories,
    updateActiveCategory
} = cartSlice.actions

export default cartSlice.reducer