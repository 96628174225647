import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isAuthenticated: false, 
    user: null
}

const authSlice = createSlice({
    name: 'auth', 
    initialState, 
    reducers: {
        updateUser: (state, action) => {
            const { user } = action.payload 
            return {
                ...state, 
                isAuthenticated: !(user === null), 
                user

            }
        },
        updateAuthStatus: (state, action) => {
            const { isAuthenticated } = action.payload 
            state.isAuthenticated = isAuthenticated 

        }
    }
})

export const { 
    updateUser,
    updateAuthStatus,
} = authSlice.actions

export default authSlice.reducer
