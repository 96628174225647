import sessionReducer, { 
    updateScreenDimensions
} from './slice'


export const SESSION_TYPES = {
    UPDATE_SCREEN_DIMENSIONS: 'UPDATE_SCREEN_DIMENSIONS'
}

export const SESSION_SLICE = {
    UPDATE_SCREEN_DIMENSIONS: updateScreenDimensions
}

export { sessionReducer}
export default sessionReducer