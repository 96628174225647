import storeReducer from './storeSlice';
import {
    updateCart,
    updateProducts,
    updateProduct,
    updateThumbnails,
    markThumbnailAsActive,
    updateCategories,
    updateActiveCategory
} from './storeSlice'



export const STORE_TYPES = {
    UPDATE_CART: 'UPDATE_CART',
    UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
    UPDATE_PRODUCT: 'UPDATE_PRODUCT',
    UPDATE_THUMBNAILS: 'UPDATE_THUMBNAILS', 
    MARK_THUMBNAIL_AS_ACTIVE: 'MARK_THUMBNAIL_AS_ACTIVE',
    UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
    UPDATE_ACTIVE_CATEGORY: 'UPDATE_ACTIVE_CATEGORY'
}

export const STORE_SLICE = {
    UPDATE_CART:   updateCart,
    UPDATE_PRODUCTS: updateProducts,
    UPDATE_PRODUCT: updateProduct,
    UPDATE_THUMBNAILS: updateThumbnails,
    MARK_THUMBNAIL_AS_ACTIVE: markThumbnailAsActive,
    UPDATE_CATEGORIES: updateCategories,
    UPDATE_ACTIVE_CATEGORY: updateActiveCategory
}


export { storeReducer };
export default storeReducer;
