import merge from 'deepmerge'
import { createTheme } from '@mui/material/styles'
import { colors } from '@mui/material/';

const theme = createTheme()

const light = merge(theme.palette, {
    mode: 'light', 
    primary: {
        main: colors.lightBlue[700],
        dark: colors.lightBlue[900],
        light: colors.lightBlue[300],
        xlight: colors.lightBlue[50]
    },
    secondary: {
        main: colors.yellow[100],
        dark: colors.yellow[900],
        light: colors.yellow[50],
        xlight: colors.yellow[50]
    },
    background: {
        main: '#f2f2f2',
        card: '#fff', 
        cardHeader: colors.yellow[100],
        content: '#fff',
        navbar: colors.yellow[50],
        footer: colors.yellow[50], 
        categories: '#222'  
    },
    links: {
        
    }
})

export default {
    light
}