import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    screen: {
        width: 0,
        height: 0, 
        header: 0, 
        footer: 0
    }
}

const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        updateScreenDimensions: (state, action) => {

            return {
                ...state,
                screen: {
                    ...action.payload
                }
            }
        }
    }

})



const { updateScreenDimensions } = sessionSlice.actions

export {
    updateScreenDimensions
}
export default sessionSlice.reducer